import { createAuth0 } from "@auth0/auth0-vue";
export default defineNuxtRouteMiddleware(async () => {
  if (process.client) {

    const { $auth0 } = await useNuxtApp();

    if ($auth0) {
      if (!$auth0.isAuthenticated.value) {
        // console.log(useRoute().path);
        $auth0.loginWithRedirect({
          appState: {
            target: useRoute().path,
          },
        });
      }
    }
  }
});
